<template>
  <el-row class="resetpassword">
    <el-card shadow="never">
      <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
        <el-col :span="24">
          <el-form-item>
            <h4>パスワードをリセットします。</h4>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label=""
            prop="email"
            :rules="[
              {
                required: true,
                message: 'EmailAddressを入力してください。',
                trigger: 'blur',
              },
              {
                type: 'email',
                message: 'EmailAddressを正しく入力してください。',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input
              type="age"
              v-model.number="ruleForm.email"
              autocomplete="off"
              placeholder="メール"
              :disabled="this.status"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="this.status">
          <el-form-item label="" prop="rand_num">
            <el-input
              type="password"
              v-model="ruleForm.rand_num"
              autocomplete="off"
              placeholder="BRSコード"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item v-if="!this.status">
            <el-button type="primary" @click="submitForm('ruleForm')">
              <span>コード発行</span>
            </el-button>
          </el-form-item>
          <el-form-item v-else>
            <el-button type="primary" @click="submitFormRandNum('ruleForm')">
              <span>送信</span>
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <h5>
              Password思い出しましたか？
              <el-link href="/#/signin" :underline="false" type="primary"
                >サインイン</el-link
              >
              へ
            </h5>
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
  </el-row>
</template>

<script>
import { checkEmail, checkRandNum } from "api/user";
import { Message } from "element-ui";

export default {
  name: "ResetPassword",
  data() {
    return {
      status: false,
      ruleForm: {
        email: "",
        rand_num: "",
      },
    };
  },
  methods: {
    checkEmail() {
      let values = {};
      values["email"] = this.ruleForm.email;
      checkEmail(values)
        .then((res) => {
          if (res.data.status === 1) {
            this.status = true;
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkRandNum() {
      let values = {};
      values["email"] = this.ruleForm.email;
      values["rand_num"] = this.ruleForm.rand_num;
      checkRandNum(values)
        .then((res) => {
          if (res.data.status === 1) {
            clearTimeout(this.timer); //清除延迟执行
            this.timer = setTimeout(() => {
              this.$router.push({
                path: "PasswordInput",
                query: {
                  email: this.ruleForm.email,
                  rand_num: this.ruleForm.rand_num,
                },
              });
            }, 3000);

            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.checkEmail();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitFormRandNum(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.checkRandNum();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style>
.el-button {
  width: 100%;
}

.resetpassword {
  margin: 0;
  padding: 5% 20% 0 58%;
  background-image: url("../../assets/img/5626873.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
</style>
