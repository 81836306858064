var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{staticClass:"resetpassword"},[_c('el-card',{attrs:{"shadow":"never"}},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',[_c('h4',[_vm._v("パスワードをリセットします。")])])],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"","prop":"email","rules":[
            {
              required: true,
              message: 'EmailAddressを入力してください。',
              trigger: 'blur',
            },
            {
              type: 'email',
              message: 'EmailAddressを正しく入力してください。',
              trigger: ['blur', 'change'],
            },
          ]}},[_c('el-input',{attrs:{"type":"age","autocomplete":"off","placeholder":"メール","disabled":this.status},model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", _vm._n($$v))},expression:"ruleForm.email"}})],1)],1),(this.status)?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"","prop":"rand_num"}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"BRSコード"},model:{value:(_vm.ruleForm.rand_num),callback:function ($$v) {_vm.$set(_vm.ruleForm, "rand_num", $$v)},expression:"ruleForm.rand_num"}})],1)],1):_vm._e(),_c('el-col',{attrs:{"span":24}},[(!this.status)?_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_c('span',[_vm._v("コード発行")])])],1):_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitFormRandNum('ruleForm')}}},[_c('span',[_vm._v("送信")])])],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',[_c('h5',[_vm._v(" Password思い出しましたか？ "),_c('el-link',{attrs:{"href":"/#/signin","underline":false,"type":"primary"}},[_vm._v("サインイン")]),_vm._v(" へ ")],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }